import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { SxProps } from '@mui/material';
import { Box } from '@mui/system';
import BannerPromotionCarousel from '@/components/BannerPromotionCarousel';
import { style } from '@/styles/globals';
import { useAppSelector } from '@/utils/useGetBusRouteDataWithFilter';

interface IBannerDiscount {
  isDesktop?: boolean
  isRoutePage?: boolean
  sx?: SxProps
  isHiddenTitle?: boolean
  vehicleType?: 'bus' | 'flight' | undefined
}

function BannerDiscount(props: IBannerDiscount) {
  const {
    isDesktop, sx, isHiddenTitle, isRoutePage, vehicleType,
  } = props;
  const imagesPromotion = useAppSelector((state) => state.strapi.imagesPromotion);
  let images = [];
  if (isDesktop) {
    if (!isRoutePage) {
      images = (vehicleType === 'bus' ? imagesPromotion?.BANNER_IMAGE_DESKTOP_HOME_BUS : imagesPromotion?.BANNER_IMAGE_DESKTOP_HOME_FLIGHT) || [];
    } else {
      images = (vehicleType === 'bus' ? imagesPromotion?.BANNER_IMAGE_DESKTOP_ROUTE_BUS : imagesPromotion?.BANNER_IMAGE_DESKTOP_ROUTE_FLIGHT) || [];
    }
  } else if (!isRoutePage) {
    images = (vehicleType === 'bus' ? imagesPromotion?.BANNER_IMAGE_MOBILE_HOME_BUS : imagesPromotion?.BANNER_IMAGE_MOBILE_HOME_FLIGHT) || [];
  } else {
    images = (vehicleType === 'bus' ? imagesPromotion?.BANNER_IMAGE_MOBILE_ROUTE_BUS : imagesPromotion?.BANNER_IMAGE_MOBILE_ROUTE_FLIGHT) || [];
  }
  const handlerClickBanner = (url?: string) => {
    if (url) window.open(url, '_blank');
  };
  if (images?.length === 0) {
    return <></>;
  }
  return (
    <Stack sx={{
      gap: isDesktop ? '40px' : '16px',
      ...sx,
    }}
    >
      {isHiddenTitle && !isRoutePage && (
        <Typography
          component="h2"
          fontWeight="500"
          textAlign={isDesktop ? 'center' : 'left'}
          variant={isDesktop ? 'heading1' : 'title'}
          sx={{
            color: 'black',
            fontSize: '22px',
            marginLeft: '10px',
          }}
        >
          Chương trình giảm giá
        </Typography>
      )}
      {!isHiddenTitle && isDesktop && (
        <Typography
          component="h2"
          fontWeight="500"
          textAlign={isDesktop ? 'center' : 'left'}
          variant={isDesktop ? 'heading1' : 'title'}
        >
          Chương trình giảm giá
        </Typography>
      )}
      {!isHiddenTitle && !isDesktop && (
        <Stack
          direction="row"
          sx={{
            width: '100%',
            gap: '16px',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '18px',
              lineHeight: '24px',
              color: style.color.black2,
            }}
          >
            Ưu đãi nổi bật
          </Typography>
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '20px',
              color: style.color.product3,
            }}
          >
            Xem thêm
          </Typography>
        </Stack>
      )}
      {isDesktop && !isRoutePage && (
        <Stack
          direction="row"
          sx={{
            width: '100%',
            gap: '22px',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {images?.map((image) => (
            <Box
              key={image.image}
              style={{
                width: '100%',
              }}
              onClick={() => handlerClickBanner(image?.url)}
            >
              <img
                src={image?.image}
                alt="banner-home"
                height="auto"
                style={{
                  width: '100%',
                  maxHeight: '221px',
                }}
              />
            </Box>
          ))}
        </Stack>
      )}
      {!isDesktop && !isRoutePage && (
        <Stack
          direction="row"
          sx={{
            width: '100%',
            gap: '16px',
            justifyContent: 'flex-start',
            alignItems: 'center',
            overflow: 'auto !important',
          }}
        >
          {images?.map((image) => (
            <Box
              key={image.image}
              onClick={() => handlerClickBanner(image?.url)}
            >
              <img
                src={image.image}
                alt="banner-home"
                style={{
                  // width: images.length === 1 ? '100%' : '267px',
                  height: '100px',
                  borderRadius: '8px',
                  boxShadow: '0px 4px 32px -4px rgba(0, 0, 0, 0.16)',

                }}
              />
            </Box>
          ))}
        </Stack>
      )}
      {isDesktop && isRoutePage && (
        <Box sx={{
          height: isDesktop ? '162px' : '193px',
          width: '100%',
        }}
        >
          <BannerPromotionCarousel
            imagesUrl={images}
            isDesktop
            handlerClickBanner={handlerClickBanner}
          />
        </Box>

      )}
      {!isDesktop && isRoutePage && (
        <Box sx={{
          height: isDesktop ? '162px' : '120px',
          width: '100%',
        }}
        >
          <BannerPromotionCarousel
            imagesUrl={images}
            isDesktop={isDesktop}
            handlerClickBanner={handlerClickBanner}
          />
        </Box>

      )}
    </Stack>

  );
}

export default BannerDiscount;
