import React from 'react';
import Head from 'next/head';

const HomepageMetaHeader = ({ isDesktop }: { isDesktop?: boolean }) => {
  const path = typeof window !== 'undefined' ? window.location.href : '';
  const hostName = typeof window !== 'undefined' ? `${window.location.protocol}//${window.location.host}` : '';
  return (
    <Head>
      <title>Goyolo.com | So sánh giờ và giá vé máy bay, xe khách, xe lửa | Đặt vé phương tiện tối ưu đi mọi nơi</title>
      <meta
        name="description"
        content="Goyolo.com - Giải pháp đặt vé cho phép so sánh giá cả, giờ khởi hành, tổng thời gian di chuyển giữa máy bay, xe khách, xe lửa trên cùng 1 màn hình"
      />
      {' '}
      <meta
        name="google-site-verification"
        content={process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION}
      />
      <meta
        name="og:type"
        property="og:type"
        content="Product"
      />
      <meta
        name="og:title"
        property="og:title"
        content="Goyolo.com | So sánh giờ và giá vé máy bay, xe khách, xe lửa | Đặt vé phương tiện tối ưu đi mọi nơi"
      />
      <meta
        name="og:description"
        property="og:description"
        content="Goyolo.com - Giải pháp đặt vé cho phép so sánh giá cả, giờ khởi hành, tổng thời gian di chuyển giữa máy bay, xe khách, xe lửa trên cùng 1 màn hình"
      />
      <meta
        name="og:image"
        property="og:image"
        content={`${hostName}/images/${isDesktop ? 'banner01.png' : 'banner02.png'}`}
      />
      <meta
        name="og:image:alt"
        property="og:image:alt"
        content="Goyolo.com săn vé máy bay, xe khách, xe lửa giá rẻ"
      />
      <meta
        name="og:locale"
        property="og:locale"
        content="vi"
      />
      <meta
        name="og:url"
        property="og:url"
        content={path}
      />
      <meta
        name="fb:app_id"
        property="fb:app_id"
        content="https://www.facebook.com/Goyolocom-103117924769137"
      />
      <meta
        name="og:site_name"
        property="og:site_name"
        content="Goyolo.com"
      />
    </Head>
  );
};

export default HomepageMetaHeader;
