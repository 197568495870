// import { resetTimeoutSearchFlight } from './eventEmitter';
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';
import { format } from 'date-fns';
import type { AppDispatch, RootState } from './store';
// import { useEffect } from 'react';
import { generateTimeId } from './generateTimeId';
import { useGetBusDataQuery } from '../slices/bus/busData/apis/busDataApi';
import { setBusDataIsDone, setSortedBest } from '@/slices/bus/busData/slices/busDataSlice';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
let lastRequestObjStr = '';

export const useGetBusRouteDataWithFilter = (pollingInterval = 0, isReturn?: boolean) => {
  const dispatch = useAppDispatch();

  const startPoint = useAppSelector(
    (state) => state.searchBusForm.startPoint,
  );
  const toPoint = useAppSelector((state) => state.searchBusForm.toPoint);
  const selectedDate = useAppSelector((state) => state.calendar.selectedDate);
  const fromId = startPoint?.id;
  const toId = toPoint?.id;
  const sort = useAppSelector((state) => state.sortingBusRoute.selectedSort);
  const filterBusRoute = useAppSelector((state) => state.filterBusRoute.filter);
  const dataSelected = (isReturn && selectedDate.return)
    ? selectedDate.return : selectedDate.depart;
  // useEffect(() => {
  //   resetTimeoutSearchFlight();
  // }, [toPoint, startPoint, selectedDate, sort, filterBusRoute]);

  const queryParams = {
    from: Number((isReturn ? toId : fromId) || '0'),
    to: Number((isReturn ? fromId : toId) || '0'),
    date: format(new Date(dataSelected), 'yyyy-MM-dd'),
    ...filterBusRoute,
    sort,
    // time_id: generateTimeId(),
  };
  if (sort.type === 'best') {
    dispatch(setSortedBest(true));
  } else {
    dispatch(setSortedBest(false));
  }
  const requestObjStr = JSON.stringify(queryParams);
  const intervalLoading = requestObjStr === lastRequestObjStr;

  if (!intervalLoading) {
    dispatch(setBusDataIsDone(false));
  }
  lastRequestObjStr = requestObjStr;

  return useGetBusDataQuery(queryParams);
};
