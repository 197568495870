/* eslint-disable @typescript-eslint/no-unused-expressions */
// @ts-nocheck
import { useState, useEffect } from 'react';

export const useCheckIsMobile = (isMobileFirst?: boolean) => {
  const [isMobile, setIsMobile] = useState(!!isMobileFirst);
  const handleResize = () => {
    if (window.innerWidth < 1024) {
      setIsMobile(true);
    } else if (window.innerWidth > 1024) {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    if (window) {
      if (window.innerWidth < 1024) {
        setIsMobile(true);
      } else if (window.innerWidth > 1024) {
        setIsMobile(false);
      }
      window.addEventListener('resize', handleResize);
    }
  }, []);
  return isMobile;
};
