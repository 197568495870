export function isElement(el: any): el is Element {
  return (
    el != null
        && typeof el === 'object'
        && 'nodeType' in el
        && el.nodeType === Node.ELEMENT_NODE
  );
}

export function getOwnerDocument(node?: Element | null): Document {
  return isElement(node) ? node.ownerDocument ?? document : document;
}

export function canUseDOM(): boolean {
  return !!(
    typeof window !== 'undefined'
      && window.document
      && window.document.createElement
  );
}

export const isBrowser = canUseDOM();
