/* eslint-disable react/no-array-index-key */
import React from 'react';
import Box from '@mui/material/Box';
import MuiCarousel from 'react-material-ui-carousel';

interface ICarousel{
  imagesUrl?: Images[];
  isDesktop?: boolean
  handlerClickBanner?: any
}

interface Images {
  image: string
  url?: string
}

const Carousel = (props: ICarousel) => {
  const {
    imagesUrl, isDesktop, handlerClickBanner,
  } = props;
  return (
    <Box>
      <MuiCarousel
        sx={{
          height: isDesktop ? '162px' : '120px',
          width: '100%',
        }}
        autoPlay
        indicators={false}
        swipe
        cycleNavigation
        navButtonsProps={{
          style: {
            backgroundColor: '#FFF',
            color: '#000',
            width: '33px',
            height: '33px',
          },
        }}
      >
        {imagesUrl?.map((x, i) => (
          <Box
            key={i}
            sx={{ width: '100%' }}
            onClick={() => handlerClickBanner(x?.url)}
          >

            <img
              alt={`media-${i}`}
              height={isDesktop ? '162px' : '120px'}
              src={x.image}
              style={{ width: '100%' }}
            />
          </Box>
        ))}
      </MuiCarousel>
    </Box>
  );
};

export default React.memo(Carousel);
