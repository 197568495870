import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

interface IComingSoonMessage{
  isDesktop?: boolean;
  open?: boolean;
  onClose?: () => any;
}

function ComingSoonMessage(props: IComingSoonMessage) {
  const { isDesktop, open, onClose } = props;
  const handleClose = () => {
    if (onClose) {
      onClose?.();
    } else {
      window.location.reload();
    }
  };
  return (
    <Modal
      open={open === undefined ? true : open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        style={style}
        width={isDesktop ? '500px' : '80vw'}
      >
        <Typography
          variant="title"
          textAlign="center"
          mb="16px"
        >
          Goyolo đang phát triển dịch vụ này
        </Typography>
        <img
          style={{ margin: '0 auto' }}
          width="250px"
          alt="image_coming_soon"
          src="/images/image_coming_soon.png"
        />
        <Typography
          mt="16px"
          sx={{ marginBottom: '24px' }}
          variant="contentRegular"
        >
          Tính năng này sẽ sớm được ra mắt. Khi đó bạn có thể dễ dàng so sánh được giá vé giữa các phương tiện máy bay, tàu hoả, xe khách trên cùng 1 màn hình
          <div style={{ marginTop: '10px' }} />
          Mong bạn tiếp tục ủng hộ Goyolo.com nhé!
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'blue3',
            height: '44px',
            ':hover': {
              backgroundColor: 'blue3',
            },
          }}
          onClick={handleClose}
        >
          <Typography color="white">Đồng ý</Typography>
        </Button>
      </Stack>
    </Modal>
  );
}
const style: React.CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  boxShadow: '0px 2px 10px rgba(7, 65, 57, 0.15)',
  padding: '16px',
  border: '1px solid #F5F7F9',
  borderRadius: '16px',
};

export default ComingSoonMessage;
